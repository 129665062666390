import React from "react"
import './styles.css'

const Loader = ({active, title = 'Loading...', message = 'This make take a few seconds, please don\'t close this page.', fullscreen = true}) => {
    const classes = [
        'top-0',
        'left-0',
        'right-0',
        'bottom-0',
        'z-50',
        'overflow-hidden',
        'bg-gray-700',
        'opacity-75',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
    ]

    if (!fullscreen) {
        classes.push('absolute')
    } else {
        classes.push('fixed')
        classes.push('w-full')
        classes.push('h-screen')
    }

    if (!active) {
        classes.push('hidden')
    }

    if (fullscreen === true) {
        //classes += ' fullscreen'
    }

    return (
        <div className={classes.join(' ')}>
            <div className={'loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4'}/>
            <h2 className={'text-center text-white text-xl font-semibold'}>{title}</h2>
            <p className={'w-1/3 text-center text-white'}>{message}</p>
        </div>
    )
}

export default Loader
