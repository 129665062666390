import React from "react"
import {InformationCircleIcon} from "@heroicons/react/outline";

const Alert = ({type = 'success', message = ''}) => {

    let setting

    switch(type) {
        case 'info':
            setting = 'text-white bg-brandBlue'
            break
        case 'warning':
            setting = 'text-white bg-orange-600'
            break
        case 'danger':
            setting = 'text-white bg-red-600'
            break
        case 'success':
            setting = 'text-white bg-brandGreen'
            break
        default:
            setting = 'text-white bg-gray-600'
    }

    return (
        <div className={'w-full flex px-6 py-4 border-0 rounded relative mb-4 border ' + setting}>
            <div className="text-xl inline-block mr-5 align-middle">
                <InformationCircleIcon className={'h-5 w-5'}/>
            </div>
            <div className="inline-block align-middle mr-8 text-lg">
                {message}
            </div>
        </div>
    )
}

export default Alert
